import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//获取标签列表
export const getTagList = () =>
  request({
    method: "post",
    url: "/adm/supervise/user/label/list",
  });

//获取标签组列表
export const getTagGroupList = () =>
  request({
    method: "post",
    url: "/adm/supervise/user/labelgroup/list",
  });

//设置/创建标签
export const setTag = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/label/set",
  });

//删除标签
export const deleteTag = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/label/del",
  });

//设置用户标签
export const setUserTag = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/risk/setlabel",
  });

//获取用户列表
export const getUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/risk/list",
  });

//设置/创建标签组
export const setTagGroup = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/labelgroup/set",
  });

//获取用户标签列表
export const getUserTagList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/risk/getlabel",
  });

//添加用户标签
export const addUserTag = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/risk/setlabel",
  });

//删除用户标签
export const deleteUserTag = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/risk/dellabel",
  });

//获取近期详情
export const getNearDetail = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/risk/recent",
  });

//获取总详情
export const getTotalDetail = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/risk/totalinfo",
  });

//获取警报详情
export const getReskDetail = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/risk/detail",
  });

//导出Execl
export const exportUserList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/supervise/user/risk/list`,
  });

//导出Execl
export const exportTotalDetail = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/supervise/user/risk/totalinfo`,
  });
